<script>
import {debounce} from 'lodash';
import DataService from "@/services/data";
import ImageService from "@/services/image";
import PrintList from "@/components/PrintList.vue";
import CardImage from "@/components/CardImage.vue";
import PrintAmountInput from "@/components/PrintAmountInput.vue";

import {SlideXLeftTransition, SlideYUpTransition, SlideYDownTransition } from 'vue2-transitions'

export default {
	name: "Printer",
	components: {
		CardImage,
		PrintList,
		PrintAmountInput,

        SlideXLeftTransition,
        SlideYUpTransition,
        SlideYDownTransition
	},
	data() {
		return {
			searchText: null,
			searchState: null, // 'loading', 'done', null (init) 
			suggestedCards: [],
			suggestedCardHoverIndex: null,

			clearConfirmVisible: false
		};
	},
	methods: {
		getCardUrl: ImageService.getCardUrl,
		search: debounce(function() {
            if (this.searchText && this.searchText.length) {
				this.searchState = 'loading'
				DataService.searchAutocomplete(this.searchText)
					.then((response) => {
						// console.log(response)
						if (response.data.length) {
							this.suggestedCards = response.data;
						} else {
							this.suggestedCards = []
						}
						this.searchState = 'done'
					})
					.catch((e) => {
						console.log(e);
					});
			}
        }, 200),
		addCard (card) {
			this.$store.commit('addPrintCard', { card } )
		},
		subtractCard (card) {
			this.$store.commit('addPrintCard', { card, addAmount: -1 })
		},
		onPrintAmountUpdated () {
			this.$store.commit('refreshPrintCards')
		},
		onSuggestedCardMouseOver (index) {
			this.suggestedCardHoverIndex = index
		},
		onSuggestedCardMouseOut () {
			this.suggestedCardHoverIndex = null
		},
		onSearchInput (value) {
			if (!value.length) {
				this.suggestedCards = []
				this.searchState = null
				return;
			}

			if (value.length >= 1) {
				this.search()
			}
		},
		getPrintAmount(cardSlug) {
			return this.$store.getters.getPrintAmount(cardSlug)
		},
		print () {
			window.print();
		},
		clear () {
			this.$store.commit('setPrintCards', [])
		},
		setClearConfirmVisible (visible) {
			console.log(`clearConfirmVisible ${visible}`)
			this.clearConfirmVisible = visible
		}
	},
	computed: {
		sortedPrintCards: {
			get () {
				return [...this.printCards].sort(function (a, b) {
					if (
						(a.types.indexOf('CHAMPION') || a.types.indexOf('REGALIA')) &&
						(!b.types.indexOf('CHAMPION') || !b.types.indexOf('REGALIA'))
						)
					{
						return 1
					}
					if (
						(b.types.indexOf('CHAMPION') || b.types.indexOf('REGALIA')) &&
						(!a.types.indexOf('CHAMPION') || !a.types.indexOf('REGALIA'))
						)
					{
						return -1
					}
					return 0
				})
			},
			set (value) {
				this.printCards = value
			}
		},
		printCards: {
			get () {
				return this.$store.state.printCards
			},
			set (value) {
				this.$store.commit('setPrintCards', value)
			}
		},
		totalPrintCards () {
			return this.$store.getters.totalPrintCards
		}
	},
	mounted() {
	}
};
</script>

<template>
	<div class="page">
		<b-container class="page__printer">
			<b-row>
                <slide-y-up-transition appear :duration=500 mode="out-in">
                    <b-col>
                        <h4>Print & Play</h4>
                        
                    </b-col>
                </slide-y-up-transition>
			</b-row>
			<b-row>
                <slide-x-left-transition appear :duration=500 mode="out-in">
                    <b-col
                        lg="6"
                    >
                        <b-list-group >
                            <b-list-group-item>
                                <h5 class="mb-3">
                                    <b-icon-Search scale="0.8" class="mr-1" />
                                    Find
                                </h5>
                                <p>
                                    <b-input-group class="">
                                        <b-input-group-prepend
                                            is-text
                                        >
                                            <div style="width: 20px">
                                                <b-icon
                                                    v-if="searchState === null"
                                                    icon="arrow-right"
                                                />

                                                <b-icon
                                                    v-if="searchState === 'loading'"
                                                    icon="arrow-clockwise"
                                                    style="color: #fff;"
                                                    animation="spin"
                                                />

                                                <b-icon
                                                    v-if="searchState === 'done'"
                                                    icon="check2"
                                                    style="color: #fff;"
                                                />

                                            </div>
                                            <!-- <b-icon icon="tag-fill" /> -->
                                        </b-input-group-prepend>

                                        <b-form-input
                                            v-model="searchText"
                                            placeholder="Enter card name"
                                            @input="onSearchInput"
                                            trim
                                            autofocus
                                        >
                                        </b-form-input>
                                    </b-input-group>
                                </p>
                            </b-list-group-item>
                            <b-list-group-item
                                v-if="suggestedCards.length === 0 && searchState === 'done'"
                            >
                                Cannot find any card name with "{{ searchText }}"
                            </b-list-group-item>
                            <b-list-group-item
                                v-if="suggestedCards.length"
                                class="page__printer__suggestion"
                            >
                                <h5 class="mb-3">
                                    <b-icon-plus-circle class="mr-1" />
                                    Add Cards
                                </h5>
                                <b-row
                                    cols-md="2"
                                    cols-lg="3"
                                >
                                    
                                    <b-col
                                        v-for="(card, index) in suggestedCards"
                                        :key="card.slug"
                                        cols="6"
                                        sm="4"
                                        md="3"
                                        :lg="false"
                                        class="py-2 px-1"
                                        @mouseover="onSuggestedCardMouseOver(index)"
                                        @mouseleave="onSuggestedCardMouseOut()"
                                    >
                                        <slide-y-down-transition appear :duration=500 mode="out-in">
                                            <b-overlay
                                                :show = "suggestedCardHoverIndex === index"
                                                bg-color="black"
                                                opacity="0.75"
                                                blur="2px"
                                                rounded="lg"
                                            >
                                                <CardImage
                                                    :slug="card.editions.length ? card.editions[0].slug : ''"
                                                    :alt="card.name"
                                                />
                                                <template #overlay>
                                                    <div class="text-center">
                                                        <div class="mb-2">
                                                            <b-icon-printer-fill scale="1.3" class="mr-2" />
                                                            Amount
                                                        </div>
                                                        
                                                        <PrintAmountInput
                                                            :card="card"
                                                            size="lg"
                                                        />
                                                    </div>
                                                </template>
                                            </b-overlay>
                                        </slide-y-down-transition>
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-list-group>
                    </b-col>
                </slide-x-left-transition>
                <slide-x-left-transition appear :duration=500 :delay="150" mode="out-in">
                    <b-col
                        lg="6"
                        class="mt-3 mt-lg-0"
                    >
                        <!-- <h4>Cards</h4> -->
                        <b-list-group
                        >
                            <b-list-group-item
                                class="flex-column align-items-start px-3"
                            >
                                <div class="d-flex w-100 justify-content-between align-items-center">
                                    <h5 class="mb-1">
                                        <b-icon-layers class="mr-1" />
                                        Print List
                                    </h5>
                                </div>

                                

                                <p  class="mt-2 mb-0">
                                    Printing is optimized for ANSI Letter sized paper (8.5 by 11 inches).
                                </p>
                                <div
                                    v-if="printCards.length"
                                    class="d-flex w-100 justify-content-between align-items-center"
                                >
                                    <b-button
                                    
                                        @click="print"
                                        class="mt-2"
                                    >
                                        <b-icon-printer class="mr-2"/>
                                        Print {{ totalPrintCards }} Card{{ totalPrintCards > 1 ? 's' : '' }}
                                    </b-button>

                                    <tippy
                                        :visible="clearConfirmVisible"
                                        interactive 
                                        :animate-fill="false"
                                        distance="7"
                                        placement="bottom" 
                                        theme="grey" 
                                        animation="fade" 
                                        trigger="manual"
                                        arrow
                                        @hide="clearConfirmVisible = false"
                                    >
                                        <template v-slot:trigger>
                                            <b-button
                                                class="mt-2"
                                                @click="clearConfirmVisible = true"
                                            >
                                                <b-icon-trash2-fill scale="0.9" class="mr-1" />
                                                Clear
                                            </b-button>
                                        </template>

                                        <span>
                                            <div class="p-1 text-danger" >
                                                <b-icon-exclamation-diamond scale="1.7" class="m-2" /><br>
                                                Remove all cards?
                                            </div>
                                            <b-button-group class="py-1">
                                                <b-button @click="clear">
                                                    <b-icon-check scale="1.2" class="mr-1" />
                                                    Yes
                                                </b-button>
                                                <b-button @click="clearConfirmVisible = false">
                                                    <b-icon-x scale="1.3" class="mr-1" />
                                                    No
                                                </b-button>
                                            </b-button-group>
                                        </span>
                                    </tippy>					

                                </div>
                            </b-list-group-item>
                            <b-list-group-item
                                v-if="printCards.length"
                                class="p-0"
                            >
                                <PrintList 
                                    v-model="sortedPrintCards"
                                    @print-amount-updated="onPrintAmountUpdated"
                                />
                            </b-list-group-item>
                            <b-list-group-item
                                v-if="!printCards.length"
                                class="text-muted"
                            >
                                The list is empty. Start adding cards to see them here.
                            </b-list-group-item>
                            <div class="py-3 px-2 text-muted" >
                                <b-icon-info-circle scale="1.1" class="mr-1" />
                                Print & Play feature is intended for unofficial usage only. These printed cards are not legal for use in official Grand Archive tournaments.
                            </div>
                            
                        </b-list-group>
                        
                    </b-col>
                </slide-x-left-transition>
			</b-row>
			
		</b-container>
		<div class="page__print-content">
			<template
				v-for="(card) in sortedPrintCards"
			>
				<img
					v-for="i in card.printAmount"
					:key="`${card.slug}-${i}`"
					:src="card.editions.length ? getCardUrl(card.editions[0].slug) : 'https://cdn.gatcg.com/images/back.jpg'"
					class="page__print-content__card"
				/>
			</template>
		</div>
	</div>
</template>

<style lang="scss">
	@media screen {
		.page {
			&__print-content {
				&__card {
					width: 33.3%;
				}
				display:none;
			}
			&__printer {
				margin-bottom: 300px;

				&__suggestion {
				}
			}
		}
	}
	@media print {
		@page {
			// size: 8.5in 11in;
			size: 1360px 1760px;
			margin: 40px 80px;
		}
		body {
			width: 100%;
			background: yellow;
		}
		.main {
			min-height: initial;
		}
        .navbar {
            display: none;
        }
		.container {
			margin: 0;
			padding: 0;
			max-width: 100%;
		}
		.page {
			&__printer {
				display:none;
			}

			&__print-content {
				width: 100%;
				background-color: red;
				&__card {
					width: 33.3%;
					float: left;
					// width: 33.33vw;
					// width: 2.5in;
					// height: 3.5in;
				}
			}
		}	
		.footer {
			display: none;
		}
	}
</style>