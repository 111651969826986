<script>
import TypeIcon from "@/components/TypeIcon.vue";
import CardImage from "@/components/CardImage.vue";

export default {
	name: "PrintList",
    components: {
        CardImage,
		TypeIcon,
    },
	props: {
        value: Array
	},
    data() {
		return {
            hoverCardSlug: null,
            hoverCardName: null
		};
	},
    methods: {
        deleteCard (index) {
            // this.cards.splice(index, 1) // splice does not work because it does not trigger reactivity
            this.cards =  [...this.cards.slice(0, index), ...this.cards.slice(index + 1)]
            this.$emit('print-amount-updated')
        },
        onInput (index, value) {
            if (value === 0) {
                this.deleteCard(index)
                return
            }

            this.$emit('print-amount-updated')
        },
        onNameMouseEnter ({name, slug}) {
            this.hoverCardSlug = slug;
            this.hoverCardName = name;
            this.$refs.cardTooltip.tippy().show()
        },
        onNameMouseLeave () {
            this.hoverCardSlug = null;
            this.hoverCardName = null;
            this.$refs.cardTooltip.tippy().hide()
        }

    },
    computed: {
        cards: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value) 
            }
        },
        sortedCards () {
            return [...this.cards].sort(function (a, b) {
                if (
                    (a.types.indexOf('CHAMPION') || a.types.indexOf('REGALIA')) &&
                    (!b.types.indexOf('CHAMPION') || !b.types.indexOf('REGALIA'))
                    )
                {
                    return 1
                }
                if (
                    (b.types.indexOf('CHAMPION') || b.types.indexOf('REGALIA')) &&
                    (!a.types.indexOf('CHAMPION') || !a.types.indexOf('REGALIA'))
                    )
                {
                    return -1
                }
                return 0
            })
        }
    },
    mounted() {
    }
};
</script>

<template>
    <div class="print-list">
        <tippy
            ref="cardTooltip"
            :animate-fill="false"
            :flip-on-update="true"
            animation="none"
            theme="no-bg"
            follow-cursor
            placement="right-end"
            max-width="300px"
        >
            <CardImage
                v-if="hoverCardSlug"
                :slug="hoverCardSlug"
                :alt="hoverCardName"
            />
        </tippy>
        <b-table-simple
            class="table-prints"
            hover
        >
            <!-- <b-thead head-variant="dark">
                <b-tr class="d-md-table-row d-sm-none d-none">
                    <b-th class="table-prints__delete-col">
                        
                    </b-th>
                    <b-th class="table-prints__amount-col">
                        Amount
                    </b-th>
                    <b-th>Name</b-th>
                   
                </b-tr>
            </b-thead> -->
            
            <b-tbody>
                <b-tr
                    v-for="(card, index) in cards"
                    :key="`${card.name}-${index}`"
                >
                    <!-- large device columns -->
                    <b-td
                        class="pl-3"
                        @mouseenter="onNameMouseEnter({ name: card.name, slug: card.editions.length ? card.editions[0].slug : '' })"
                        @mouseleave="onNameMouseLeave"
                    >
                        <TypeIcon
                            :types="card.types"
                            class="mr-2"
                        />{{ card.name }}
                    </b-td>
                    
                    <b-td class="table-prints__amount-col">
                        <!-- <b-form-spinbutton
                            v-model="card.printAmount"
                            min="0"
                            max="99"
                            step="1"
                            size="sm"
                            @input="onInput(index, $event)"
                        ></b-form-spinbutton> -->

                        <b-input-group size="sm">
                            <b-input-group-prepend>
                                <b-button size="sm"
                                    @click="deleteCard(index)"
                                >
                                    <b-icon-x shift-v="-1" scale="1.1" />
                                </b-button>
                            </b-input-group-prepend>
                            <b-form-spinbutton
                                v-model="card.printAmount"
                                min="0"
                                max="99"
                                step="1"
                                size="sm"
                                @input="onInput(index, $event)"
                            ></b-form-spinbutton>
                            
                        </b-input-group>
                    </b-td>
                    
                    <!-- <b-td class="table-prints__delete-col">
                        <b-button size="sm"
                            @click="deleteCard(index)"
                        >
                            <b-icon-x shift-v="-1" scale="1.1" />
                        </b-button>
                    </b-td> -->

                </b-tr>
            </b-tbody>
        </b-table-simple>
	</div>
</template>


<style lang="scss" scoped>
.table-prints {
    tr:first-child td {
        border-top: none;
    }
    td {
        vertical-align: middle;
    }
    &__delete-col {
        width: 60px;
    }
    &__amount-col {
        width: 180px;
    }
}
</style>
